import React from "react";
import Helmet from "react-helmet";
import { Link } from 'gatsby';
import { graphql } from 'gatsby'

export default function Template({
  data 
}) {
  const post = data.markdownRemark; 
  return (
    <div className="blog-post-container transparentContainer" style={{'paddingBottom':'2em'}}>
    <Helmet title={`${post.frontmatter.title} - Barcelona Code School's Blog`}
    meta={[
      {name: 'charset', content: 'UTF-8'}, 
      {name: "viewport", content: "initial-scale = 1.0"},
      {name: 'og:locale', content: 'en_US' },
      {name: 'og:type', content: 'article'},
      { name: 'description', content: `${post.frontmatter.description}` },
      { name: 'keywords', content: `${post.frontmatter.keywords}` },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'og:image', content: `${post.frontmatter.image}`},
      { name: 'image', content: `${post.frontmatter.image}`},
      { name: 'twitter:card', content: "summary_large_image"},
      { name: 'og:url', content: `https://barcelonacodeschool.com${post.frontmatter.path}`},
      { name: 'og:title', content: `${post.frontmatter.title}`},
      { name: 'og:description', content: `${post.frontmatter.description}`}
      ]} 
      />
      <div className="blog-post">
      <h1>{post.frontmatter.title}</h1>
      <div
      className="blog-post-content whiteCard"
      dangerouslySetInnerHTML={{ __html: post.html }}
      />
      </div>
      <Link
      style={{float:'right'}}
      to='/blog/'>
      Back to posts
      </Link>
      </div>
      );
}
export const pageQuery = graphql`
query BlogPostByPath($path: String!) {
  markdownRemark(frontmatter: { path: { eq: $path } }) {
    html
    frontmatter {
      date(formatString: "MMMM DD, YYYY")
      path
      title
      description
      keywords
      image
    }
  }
}
`
;